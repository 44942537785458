import {InjectionToken} from '@angular/core';

import {SeazModalConfig} from '../interfaces/seaz-modal-config';

export const MODAL_DATA = new InjectionToken('ModalData');
export const MODAL_ADDITIONAL_CONFIG = new InjectionToken('ModalAdditionalConfig');
export const SEAZ_MODAL_CONFIG =  new InjectionToken<SeazModalConfig>('SeazModalConfig');

export const DEFAULT_SEAZ_MODAL_CONFIG = {
  panelClass: ['seaz-modal', 'seaz-rounded'],
  backdropClass: 'seaz-background',
} as const;
