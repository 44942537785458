import {Overlay, OverlayModule} from '@angular/cdk/overlay';
import {PortalModule} from '@angular/cdk/portal';
import {CommonModule} from '@angular/common';
import {Injector, NgModule, NgZone} from '@angular/core';
import {MODAL_DATA, SEAZ_MODAL_CONFIG} from '@modules/modal/classes/modal-data';

import {CustomOverlayRef} from './classes/custom-overlay-ref';
import {OverlayComponent} from './components/overlay/overlay.component';
import {SeazModalConfig} from './interfaces/seaz-modal-config';
import {OverlayRefStorageService} from './services/overlay-ref-storage.service';
import {OverlayService} from './services/overlay.service';

export const overlayServiceFactory = (
  overlay: Overlay,
  injector: Injector,
  config: SeazModalConfig,
  zone: NgZone,
  overlayRefStorageService: OverlayRefStorageService,
): OverlayService => new OverlayService(overlay, injector, config, zone, overlayRefStorageService);

@NgModule({
  declarations: [
    OverlayComponent,
  ],
  imports: [
    CommonModule,
    OverlayModule,
    PortalModule,
  ],
  providers: [
    {provide: MODAL_DATA, useValue: {}},
    {provide: SEAZ_MODAL_CONFIG, useValue: {}},
    {provide: CustomOverlayRef, useValue: {}},
    {
      provide: OverlayService,
      useFactory: overlayServiceFactory,
      deps: [Overlay, Injector, SEAZ_MODAL_CONFIG, NgZone, OverlayRefStorageService],
    },
  ],
})
export class ModalModule {
}
