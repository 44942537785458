import {BlockScrollStrategy} from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  Type,
} from '@angular/core';
import {ActivatedRoute, Router, RoutesRecognized} from '@angular/router';
import {modalDrawerAnimations} from '@modules/modal/animations/modal-drawer-animation';
import {OverlayRefStorageService} from '@modules/modal/services/overlay-ref-storage.service';
import {filter, takeUntil} from 'rxjs/operators';

import {DestroySubscription} from '@helpers/destroy-subscription';
import {addPointerEvents, addScroll, removePointerEvents, removeScroll} from '@helpers/scroll-top';

import {CustomOverlayRef, MODAL_ADDITIONAL_CONFIG} from '../../classes';
import {CustomOverlayConfig} from '../../interfaces/custom-overlay-config';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  animations: [modalDrawerAnimations.transformDrawer],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class OverlayComponent extends DestroySubscription implements OnInit, OnDestroy {

  contentType: 'template' | 'string' | 'component';
  content: string | TemplateRef<any> | Type<any>;
  context;
  showCloseBtn = true;

  @HostBinding('@transform')
    animationState: 'open-instant' | 'open' | 'void' = 'void';

  constructor(
    private readonly ref: CustomOverlayRef,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    @Inject(MODAL_ADDITIONAL_CONFIG) private readonly customOverlayConfig: CustomOverlayConfig,
    private readonly overlayRefStorage: OverlayRefStorageService,
  ) {
    super();
  }

  ngOnInit() {

    this.content = this.ref.content;
    this.detectContentType();

    const config = this.ref.overlay.getConfig();
    if (config.scrollStrategy instanceof BlockScrollStrategy) {
      removeScroll();
    }
    if (config.disposeOnNavigation) {
      this.disposeOnNavigation();
    }

    const {overlayPointerEvents, animation} = this.customOverlayConfig;
    if (overlayPointerEvents) {
      addPointerEvents();
    }
    this.animationState = animation ? 'open' : 'open-instant';
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.ref.removeLastModalRef();
    const hasOpenedModalWithGlobalPosition = this.overlayRefStorage.hasOpenedModalWithGlobalPosition();
    if (hasOpenedModalWithGlobalPosition) {
      return;
    }
    addScroll();
    const customConfig = this.customOverlayConfig;
    if (customConfig && customConfig.overlayPointerEvents) {
      removePointerEvents();
    }
  }

  close() {
    this.ref.close(this.ref.backdropClickData);
  }

  private detectContentType(): void {
    if (typeof this.content === 'string') {
      this.contentType = 'string';
    } else if (this.content instanceof TemplateRef) {
      this.contentType = 'template';
      this.context = {
        close: this.ref.close.bind(this.ref),
      };
    } else {
      this.contentType = 'component';
    }
    const customConfig = this.customOverlayConfig;
    if (customConfig && customConfig.disableCloseBtn) {
      this.showCloseBtn = false;
    }
  }

  private disposeOnNavigation(): void {
    const currentUrl = this.router.url;
    this.router.events.pipe(
      filter((e) => e instanceof RoutesRecognized),
      takeUntil(this.destroyStream$),
    ).subscribe((e: RoutesRecognized) => {
      if (e.urlAfterRedirects !== currentUrl) {
        this.close();
      }
    });
  }
}
