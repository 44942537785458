<div class="modal__wrap">
  <ng-container [ngSwitch]="contentType">
    <ng-container *ngSwitchCase="'string'">
      <div class="box">
        <div [innerHTML]="content"></div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'template'">
      <ng-container *ngTemplateOutlet="content; context: context"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'component'">
      <ng-container *ngComponentOutlet="content"></ng-container>
    </ng-container>
  </ng-container>
  <button *ngIf="showCloseBtn" (click)="close()" class="modal-close" aria-label="close">
    <i class="icon-close"></i>
  </button>
</div>
